import { create } from "zustand"

interface LibraryState {
  selectedBooks: Set<string>
  showCheckbox: boolean
  setShowCheckbox: (showCheckbox: boolean) => void
  toggleShowCheckbox: () => void
  deselectBooks: () => void
  toggleSelectedBook: (bookId: string) => void
  selectAllBooks: (books: { book: { id: string } }[]) => void
  resetSelectedBooks: () => void
  isRefetchingBooks: boolean
  setIsRefetchingBooks: (isRefetchingBooks: boolean) => void
  // deleteSelectedBooks: () => void
}

const useLibraryStore = create<LibraryState>((set) => ({
  selectedBooks: new Set(),
  setShowCheckbox: (showCheckbox) => set({ showCheckbox }),
  selectAllBooks: (books) => {
    set({ selectedBooks: new Set(books.map((book) => book?.book?.id)) })
  },
  deselectBooks: () => set({ selectedBooks: new Set() }),
  showCheckbox: false,
  toggleShowCheckbox: () => {
    set((state) => ({ showCheckbox: !state.showCheckbox }))
    set({ selectedBooks: new Set() })
  },

  toggleSelectedBook: (bookId) =>
    set((state) => {
      const newSelectedBooks = new Set(state.selectedBooks)
      if (newSelectedBooks.has(bookId)) {
        newSelectedBooks.delete(bookId)
      } else {
        newSelectedBooks.add(bookId)
      }
      return { selectedBooks: newSelectedBooks }
    }),

  isRefetchingBooks: false,
  setIsRefetchingBooks: (isRefetchingBooks) => set({ isRefetchingBooks }),

  resetSelectedBooks: () => {
    set({ selectedBooks: new Set() })
    set({ showCheckbox: false })
  },
}))

export default useLibraryStore

/* eslint-disable @typescript-eslint/no-explicit-any */
import { LIBRARY_BOOKS_PER_PAGE } from "@/constants"
import { ReadingStatusEnum } from "@/graphql/generated/types"
import { ApolloCache, Reference } from "@apollo/client"

const generateFieldKey = (fieldName: any, args: any) => {
  return `${fieldName}(${JSON.stringify(args)})`
}

export const handleShelfModification = (
  cache: ApolloCache<any>,
  bookIds: string[],
  userId: string,
  shelfIds: string[],
  operation: "add" | "remove",
  currentShelf: string,
  currentPage: number
) => {
  // const userId = cache.identify(cache.data.data.ROOT_QUERY)
  const userRef = cache.identify({ __typename: "User", id: userId })

  // Helper function to update items list - reusable for both queries
  const updateItems = (
    existingItems: any[],
    readField: any,
    toReference: any
  ) => {
    // Create shelf references inside the callback where toReference is available
    const shelfRefs = shelfIds
      .map((shelfId) => toReference({ __typename: "Shelf", id: shelfId }))
      .filter(Boolean) as Reference[]

    return existingItems.map((bookRef: any) => {
      const book = readField("book", bookRef)
      const currentBookId = readField("id", book)

      if (!bookIds.includes(currentBookId)) return bookRef

      const existingShelves = readField("shelves", bookRef) || []
      let newShelves

      if (operation === "add") {
        const existingIds = new Set(
          existingShelves.map((shelf: any) => readField("id", shelf))
        )
        newShelves = [
          ...existingShelves,
          ...shelfRefs.filter((ref) => !existingIds.has(readField("id", ref))),
        ]
      } else {
        const removeIds = new Set(shelfRefs.map((ref) => readField("id", ref)))
        newShelves = existingShelves.filter(
          (shelf: any) => !removeIds.has(readField("id", shelf))
        )
      }

      return {
        ...bookRef,
        shelves: newShelves,
      }
    })
  }

  if (currentShelf === "ALL") {
    cache.modify({
      id: userRef,
      fields: {
        // Always update the main libraryBooks list
        libraryBooks(existing, { readField, toReference }) {
          const existingItems: any = readField("items", existing) || []
          const updatedItems = updateItems(
            existingItems,
            readField,
            toReference
          )

          return {
            ...existing,
            items: updatedItems,
          }
        },
      },
    })
  } else if (
    Object.values(ReadingStatusEnum).includes(currentShelf as ReadingStatusEnum)
  ) {
    try {
      const fieldName = `libraryBooksByStatus(${JSON.stringify({
        page: currentPage,
        pageSize: LIBRARY_BOOKS_PER_PAGE,
        status: currentShelf as ReadingStatusEnum,
      })})`

      cache.modify({
        id: userRef,
        fields: {
          [fieldName](existing: any, { readField, toReference }) {
            const existingItems: any = readField("items", existing) || []
            const updatedItems = updateItems(
              existingItems,
              readField,
              toReference
            )

            return {
              ...existing,
              items: updatedItems,
            }
          },
        },
      })
    } catch (err) {
      console.error(err)
    }
  } else {
    try {
      //       // === Step 1: Generate the Field Key ===
      // const currentShelf = "2021" // Replace with your dynamic shelf slug
      const fieldName = generateFieldKey("shelf", { slug: currentShelf })

      // === Step 3: Modify the Shelf's books Field ===
      cache.modify({
        id: userRef,
        fields: {
          [fieldName](existingShelfRef: any, { readField }) {
            if (!existingShelfRef) {
              console.warn(`Shelf with slug "${currentShelf}" does not exist.`)
              return existingShelfRef
            }

            // === Step 4a: Extract Shelf ID and Typename ===
            const shelfId = readField("id", existingShelfRef)
            const shelfTypename = readField("__typename", existingShelfRef)

            if (!shelfId || !shelfTypename) {
              console.warn("Shelf ID or __typename is missing.")
              return existingShelfRef
            }

            const shelfCacheId = `${shelfTypename}:${shelfId}`

            // === Step 4b: Modify the Shelf's books using updateItems ===
            cache.modify({
              id: shelfCacheId,
              fields: {
                books(existingBooks = {}, { readField, toReference }) {
                  const existingItems: any =
                    readField("items", existingBooks) || []

                  const updatedItems = updateItems(
                    existingItems,
                    readField,
                    toReference
                  )

                  return {
                    ...existingBooks,
                    items: updatedItems,
                  }
                },
              },
            })

            // Return the existing Shelf reference after modification
            return existingShelfRef
          },
        },
      })
    } catch (err) {
      console.error("Error modifying shelves:", err)
    }
  }
}
